import Vue from 'vue'
import updated_by_others from '@/shared/updated_by_others_mixin'

//// https://v2.vuejs.org/v2/api/#provide-inject

//// bindings are not reactive, but that is fine, because they do not change
//// for staff
export default {
  mixins: [updated_by_others],
  inject: {
    isAgent: {
      default() {
        if (typeof $user !== 'undefined') {
          return $user == 'Agent'
        }
      }
    },
    isCleaner: {
      default() {
        if (typeof $user !== 'undefined') {
          return $user == 'Cleaner'
        }
      }
    },
    isClient: {
      default() {
        if (typeof $user !== 'undefined') {
          return $user == 'Client'
        }
      }
    },
    isAdmin: {
      default() {
        if (typeof $user !== 'undefined') {
          return ($user == 'GlobalAdmin') || ($user == 'ServiceAreaAdmin')
        }
      }
    },
    isGlobalAdmin: {
      default() {
        if (typeof $user !== 'undefined') {
          return $user == 'GlobalAdmin'
        }
      }
    },
    isStaff: {
      default() {
        if (typeof $user !== 'undefined') {
          return ($user == 'GlobalAdmin') || ($user == 'ServiceAreaAdmin') || ($user == 'Agent')
        }
      }
    },
    activeUser: {
      default() {
        if (typeof $user !== 'undefined') {
          if ($user) {
            return { id: $user_id, class: $user, type: $user.toLowerCase(), name: $user_name }
          }
        }
      }
    }
  }
}
